.ProseMirror {
  *:first-of-type {
    margin-top:0;
  }

  *:last-child {
    margin-bottom:0;
  }

  h1 {
    font-size:30px;
    line-height:1.125em;

  }

  h2 {
    font-size:24px;
    line-height:1.125em;
  }
}
