$base-font-size:14px;
$base-font-size-mobile:16px;
$mobile-breakpoint: 620px;

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi-Regular'),
			 url('../assets/fonts/Satoshi-Regular.woff2') format('woff');
	font-weight:normal;
}
@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi-Bold'),
	url('../assets/fonts/Satoshi-Bold.woff2') format('woff');
	font-weight:bold;
}

body {
  font-family: 'CustomFont', sans-serif;
}

html, body {
  font-size: $base-font-size;

	@media (max-width: $mobile-breakpoint) {
		font-size:$base-font-size-mobile;
	}

  margin:0;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smooth: never;
}

#root {}

#nomobile {
	padding:1rem;
	text-align: center;
	font-size:18px;
	height:100%;

	@media (min-width: $mobile-breakpoint + 1) {
		display:none;
	}
}

a, button.btn-link {
	text-decoration:underline;

	&.bold {
		font-weight:bold;
		text-decoration:none;
	}
}

h1 {
	font-size:32px;
	line-height:46px;
	font-weight:bold;
	opacity:0.9;

	@media (max-width: 420px) {
		font-size:24px;
		line-height:34px;
  }
}

hr {
	border:none;
	border-top:1px solid #ccc;
}

p, p button, li, .bigger {
	font-size:16px;
}

@each $direction in (top bottom left right){
  .margin-#{$direction}{
    margin-#{$direction}:1em !important;
    margin-#{$direction}:1rem !important;
  }

  .margin-#{$direction}-more{
    margin-#{$direction}:3em !important;
    margin-#{$direction}:3rem !important;
  }

  .margin-#{$direction}-less{
    margin-#{$direction}:.33rem !important;
    margin-#{$direction}:.33rem !important;
  }

  .nomargin-#{$direction}{
    margin-#{$direction}:0 !important;
  }
}

@each $direction in (top bottom left right){
  .padding-#{$direction}{
    padding-#{$direction}:1em !important;
    padding-#{$direction}:1rem !important;
  }

  .padding-#{$direction}-more{
    padding-#{$direction}:3em !important;
    padding-#{$direction}:3rem !important;
  }

  .padding-#{$direction}-less{
    padding-#{$direction}:.33em !important;
    padding-#{$direction}:.33rem !important;
  }

  .nopadding-#{$direction}{
    padding-#{$direction}:0 !important;
  }

  .nopadding {
  	padding:0 !important;
  }
}

button, a.button, input[type=submit] {
	background:none;
	border:none;
	font-size:$base-font-size;
	display:inline-block;
	
	@media (max-width: $mobile-breakpoint) {
		font-size:$base-font-size-mobile;
	}

	padding:.8em 2em;
	border-radius: 5px;
	text-decoration:none;

	&.bigger {
		font-size:1.4em;
	}
	
	&.btn-link {
		padding:0;
	}

	&:hover {
		cursor:pointer;
	}

	&.btn-primary {
		background: #4B706F;
		color:#fff;
	}

	&.btn-secondary {
		border-radius: 5px;
	}

	&.btn-circle {
		padding-left:0;

		&::before {
			top:-1px;
			position:relative;
			content:'+';
			display:inline-block;
			line-height:11px;
			border-radius:20px;
			width:1rem;
			height:1rem;
			color:#fff;
			margin-right:.5rem;
			background: rgba(41, 41, 41, 0.5);
		}
	}

	&:disabled {
		opacity:0.3;
	}
}

.flex {
	display: flex;
}

input {
	border:none;
	outline:none;

	&::placeholder {
		font-size:$base-font-size;
	}
}

#app-main {
	display:flex;
	flex:1;
	max-height:100%;
}

.ProseMirror-focused {
  outline: none;
}